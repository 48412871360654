const EmailIcon = ({ width, height }) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 502.073 502.073"
            height={height}
            width={width}
        >
            <path
                fill="#FFD15C"
                d="M501.812,481.176c0,6.269-2.09,11.494-6.269,15.673c-4.18,3.135-8.359,5.224-14.629,5.224H21.159
	c-5.224,0-10.449-2.09-14.629-5.224c-4.18-4.18-6.269-9.404-6.269-15.673V175.02h501.551V481.176z"
            />
            <path
                fill="#F8B64C"
                d="M495.543,496.849c-4.18,3.135-8.359,5.224-14.629,5.224H21.159c-5.224,0-10.449-2.09-14.629-5.224
	l244.506-210.024L495.543,496.849z"
            />
            <path
                fill="#40596B"
                d="M501.812,175.02l-71.053,51.2L264.62,345.339c-8.359,6.269-19.853,6.269-28.212,0L71.314,226.22
	l-71.053-51.2l71.053-51.2L225.959,13.061l11.494-8.359c8.359-6.269,19.853-6.269,28.212,0l11.494,8.359l74.188,53.29l36.571,26.122
	l42.841,31.347L501.812,175.02z"
            />
            <path
                fill="#F2F2F2"
                d="M430.759,77.845V226.22L264.62,345.339c-8.359,6.269-19.853,6.269-28.212,0L71.314,226.22V33.959
	c0-11.494,9.404-20.898,20.898-20.898h272.718L430.759,77.845z"
            />
            <path
                fill="#CDD6E0"
                d="M365.975,61.127c0,9.404,7.314,16.718,16.718,16.718h48.065l-64.784-64.784L365.975,61.127
	L365.975,61.127z"
            />
            <path
                fill="#FF7058"
                d="M312.686,223.086h16.718c-5.224,11.494-13.584,19.853-25.078,26.122
	c-12.539,7.314-28.212,10.449-47.02,10.449c-17.763,0-33.437-3.135-45.976-9.404c-12.539-6.269-22.988-14.629-29.257-27.167
	c-6.269-11.494-9.404-25.078-9.404-38.661c0-15.673,3.135-29.257,10.449-42.841c7.314-13.584,16.718-22.988,29.257-29.257
	c12.539-6.269,27.167-9.404,42.841-9.404c13.584,0,26.122,3.135,36.571,8.359c10.449,5.224,18.808,12.539,24.033,22.988
	c5.224,9.404,8.359,20.898,8.359,32.392c0,13.584-4.18,26.122-12.539,37.616c-10.449,14.629-24.033,20.898-40.751,20.898
	c-4.18,0-8.359-1.045-10.449-2.09c-2.09-2.09-4.18-4.18-4.18-7.314c-6.269,6.269-13.584,9.404-21.943,9.404
	c-9.404,0-16.718-3.135-21.943-9.404c-6.269-6.269-9.404-14.629-9.404-25.078c0-12.539,3.135-24.033,10.449-35.527
	c8.359-12.539,19.853-18.808,33.437-18.808c9.404,0,16.718,4.18,21.943,11.494l2.09-9.404h21.943l-12.539,58.514
	c-1.045,4.18-1.045,6.269-1.045,7.314s0,2.09,1.045,3.135s1.045,1.045,2.09,1.045c2.09,0,6.269-2.09,10.449-5.224
	c5.224-4.18,10.449-9.404,13.584-16.718c3.135-7.314,5.224-14.629,5.224-21.943c0-13.584-5.224-25.078-14.629-33.437
	c-9.404-9.404-22.988-13.584-40.751-13.584c-14.629,0-27.167,3.135-37.616,9.404c-10.449,6.269-17.763,14.629-22.988,25.078
	s-7.314,21.943-7.314,34.482c0,11.494,3.135,21.943,8.359,31.347c6.269,9.404,13.584,16.718,24.033,20.898
	c10.449,4.18,21.943,6.269,35.527,6.269c12.539,0,24.033-2.09,33.437-5.224C299.102,235.624,306.416,230.4,312.686,223.086z
	 M223.869,189.649c0,7.314,1.045,11.494,4.18,15.673c3.135,3.135,6.269,5.224,10.449,5.224c3.135,0,6.269-1.045,8.359-2.09
	c2.09-1.045,4.18-3.135,6.269-5.224c3.135-3.135,5.225-8.359,7.314-14.629c2.09-6.269,3.135-12.539,3.135-17.763
	c0-6.269-1.045-10.449-4.18-14.629c-3.135-3.135-6.269-5.224-10.449-5.224c-4.18,0-9.404,2.09-12.539,5.224
	c-4.18,3.135-7.314,8.359-9.404,15.673C224.914,178.155,223.869,184.424,223.869,189.649z"
            />
        </svg>
    );
};

export default EmailIcon;
