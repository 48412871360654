const data = {
    name: 'Louie Williford',
    title: 'Full Stack Software Engineer',
    landingHeadline1: 'Hi!',
    landingHeadline2: "I'm Louie.",
    landingParagraph:
        "I'm a full stack software engineer with a passion for learning about cutting edge technology. When I'm not building user-focused software, you can find me at the park with my beautiful family, in my home studio producing instrumentals, or at the gym practicing jiu jitsu!",
    contact: [
        {
            name: 'email',
            link: "mailto:thereallouiew@gmail.com?subject=Let's%20work%20together!",
            alt: 'email icon',
        },
        {
            name: 'github',
            link: 'https://www.github.com/dustyfingers',
            alt: 'github icon',
        },
        {
            name: 'linkedin',
            link: 'https://www.linkedin.com/in/louie-williford/',
            alt: 'linkedin icon',
        },
    ],
    skills: [
        'Next.js',
        'Vercel',
        'Tailwind',
        'React',
        'Redux',
        'Node.js/Express',
        'mySQL/postgreSQL',
        'MongoDB/AWS DynamoDB',
        'Javascript (ES6+)',
        'HTML',
        'CSS/SASS/LESS',
        'jQuery',
        'PHP',
        'Wordpress/UIKit',
        'Bootstrap 3/4/5',
        'AWS Amplify',
        'AWS S3',
        'AWS Lambda',
        'Git/Github',
        'Python',
    ],
    projects: [
        // TODO: add stone & co, loot fool
        // TODO: add all project images
        {
            title: 'Piggyback Studios Marketing Site',
            desc: "I designed, built, tested and deployed this application in it's entirety using Next.js, CSS and a tad of Framer Motion.",
            image: '/images/project-images/pbs.png',
            showSiteLink: true,
            siteLink: 'https://www.piggybackstudios.co',
            tags: ['Full Stack Development', 'JavaScript', 'React', 'Next.js', 'CSS'],
        },
        {
            title: 'Willis + Simpson Electrical Marketing Site',
            desc: "I built, tested and deployed this application in it's entirety using Next.js and Tailwind.",
            image: '/images/project-images/wse.png',
            showSiteLink: true,
            siteLink: 'https://www.willisandsimpsonelectrical.com',
            tags: [
                'Full Stack Development',
                'JavaScript',
                'React',
                'Next.js',
                'Tailwind',
                'Vercel',
            ],
        },
        {
            title: "Myrna's Tailor Shop Marketing Site",
            desc: "I designed, built, tested and deployed this application in it's entirety using Next.js, Tailwind and a tad of Framer Motion.",
            image: '/images/project-images/mtsm.png',
            showSiteLink: true,
            siteLink: 'https://www.myrnastailorshop.com',
            tags: [
                'Full Stack Development',
                'JavaScript',
                'React',
                'Next.js',
                'Tailwind',
                'Vercel',
            ],
        },
        {
            title: 'Extreme Bodyworks Gym Marketing Site',
            desc: "I built, tested and deployed this application in it's entirety using Next.js and Tailwind.",
            image: '/images/project-images/ebw.png',
            showSiteLink: true,
            siteLink: 'https://www.extremebodyworksgym.com',
            tags: [
                'Full Stack Development',
                'JavaScript',
                'React',
                'Next.js',
                'Tailwind',
                'Vercel',
            ],
        },
        {
            title: 'LaRock Painting and Handyman Marketing Site',
            desc: "I designed, built, tested and deployed this application in it's entirety using Next.js, Tailwind and a tad of GSAP and Framer Motion.",
            image: '/images/project-images/lph.png',
            showSiteLink: true,
            siteLink: 'https://www.larockpaintingandhandyman.com',
            tags: [
                'Full Stack Development',
                'JavaScript',
                'React',
                'Next.js',
                'Tailwind',
                'Vercel',
            ],
        },
        {
            title: 'Williford Carpentry Collective Shop',
            desc: "I designed, built, tested and deployed this application in it's entirety using Next.js, Tailwind and PostgreSQL.",
            image: '/images/project-images/wcc.png',
            showSiteLink: true,
            siteLink: 'https://www.willifordcarpentrycollective.com',
            tags: [
                'Full Stack Development',
                'JavaScript',
                'React',
                'Next.js',
                'Tailwind',
                'Stripe',
                'PostgreSQL',
                'Vercel',
            ],
        },
    ],
    experience: [
        {
            jobTitle: 'Lead Software Engineer, Founding Partner',
            employer: 'Piggyback Studios',
            bullets: [
                'Responsible for the design, development, and deployment of high-quality marketing websites and e-commerce stores for various clients, ensuring alignment with client goals and brand standards.',
                'Orchestrate marketing campaigns across multiple platforms, driving an over 200% increase in traffic to client websites on average',
                'Sell websites directly to clients by leading sales efforts. consult with clients to understand their business needs, presenting tailored web design and development solutions, and successfully closing deals on marketing sites and e-commerce projects.',
                'Implement e-commerce solutions using platforms like Stripe and Shopify optimizing for performance, scalability, and conversion rates.',
                'Collaborate closely with designers, project managers, and other stakeholders to deliver projects on time and within budget, while maintaining a high standard of code quality',
                'Develop and maintain reusable components and libraries to streamline development processes and improve efficiency across projects.',
                'Tech Stack: HTML5/CSS3/Javascript(ES6+), React/Next.js, Typescript, TailwindCSS, React Three Fiber, Framer Motion, PostgreSQL, Vercel, Git',
            ],
            location: 'Remote',
            from: 'Jan. 2024',
            to: 'Current',
        },
        {
            jobTitle: 'Software Engineer',
            employer: 'Graveflex',
            bullets: [
                'Coordinated with stakeholders, project managers and designers to build cross-platform, interactive 2D and 3D web experiences users leveraging tools like React Three Fiber, GLSL, Framer Motion and Vercel',
                'Built and maintained a wide array of web projects including ecommerce stores, marketing sites, blogs, and interactive experiences working alongside a completely remote, international team',
                'Tech Stack: HTML5/CSS3/JS(ES6+), React/Next.js, Typescript, Contentful CMS, Styled Components, React Three Fiber, Framer Motion, Ruby on Rails, PostgreSQL, Heroku, Netlify, Vercel',
            ],
            location: 'Remote',
            from: 'May 2022',
            to: 'Jan. 2024',
        },
        {
            jobTitle: 'Web Application Developer',
            employer: 'Zaronology, Inc',
            bullets: [
                'Worked with product owners and project managers to re-architect a members only streaming service to allow SSO with other web and native mobile apps',
                'Part of the team to build and launch a proprietary streaming platform into production, including personally building the checkout/user registration process, the player, administration and reporting dashboards, etc',
                'Built full stack features with junior developers, providing guidance and assistance as needed',
                'Tech Stack: React, SASS, Node.js, Bootstrap, Handlebars.js, JS, HTML5, CSS3, jQuery, Shopify, MySQL',
            ],
            location: 'Remote',
            from: 'May 2021',
            to: 'May 2022',
        },
        {
            jobTitle: 'Software Developer',
            employer: 'FitSW',
            bullets: [
                'Designed, built and tested several product-critical client facing features, such as the ability for personal trainers to create subscriptions their clients can subscribe to',
                'Built analytics and squashed bugs as they were reported by users',
                'The features I built caused an over 100% increase in fee revenue on our Stripe Connect platform by allowing personal trainers to directly sell services to their clients in-app',
                'Tech stack: PHP, MySQL,  jQuery, JS, Bootstrap, HTML5 and CSS3',
            ],
            location: 'Colorado Springs, CO',
            from: 'Jan. 2020',
            to: 'May 2021',
        },
        {
            jobTitle: 'Jr. Web Developer',
            employer: 'NativeRank',
            bullets: [
                'Built around 4 pixel-perfect custom web solutions per month for enterprise level consumers, leveraging mockups provided by the design team',
                'Tech stack: HTML/CSS, LESS, UI Kit, Handlebars.js, PHP, MySQL, and Wordpress',
            ],
            location: 'Denver, CO',
            from: 'Sep. 2019',
            to: 'Jan. 2020',
        },
    ],
    education: {
        program: 'Full Stack Web Development and Computer Science',
        school: 'Lambda School',
        from: '2019',
        to: '2021',
    },
};

export default data;
